import React from 'react'
import { useTranslation } from 'react-i18next'
import RequestTable from '../../components/broker/RequestTable'
import { ApiHook } from '../../hooks/apiHook'
import BrokerIdDetail from '../../components/broker/BrokerIdDetail'
import { useState } from 'react'
import { useSelector } from "react-redux";
import TablePagination from '../../components/Common/table/TablePagination'
function BrokerlistContainer() {
  const { t } = useTranslation()
  const selectData = [
    {
      label: "Request",
      value: "request"
    },
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Rejected",
      value: "rejected"
    },
  ]
  const [selectedValue, setselectedValue] = useState(selectData[0])
  const [searchParams, setsearchParams] = useState('')
  //  const [selectedCategory, setselectedCategory] = useState(selectData[0].value)
  const brokerIdList = ApiHook.CallBrokeridList(selectedValue?.value ?? "request", searchParams)
  const User = useSelector(
    (state) => state?.dashboard?.appLayout?.user
  );

 

  return (
    <div>
      <div className="page_head_top">{t("brokerId")}</div>


      {/* <div className="ewallet_table_section" style={{maxWidth:'1200px'}}> */}
      <div className="ewallet_table_section"> 
        <BrokerIdDetail
          t={t}
          selectedValue={selectedValue}
          setselectedValue={setselectedValue}
          selectData={selectData}
          setsearchParams={setsearchParams}
        />
        <div className="table-responsive min-hieght-table">

          <RequestTable
            brokerIdList={brokerIdList?.data}
            loading={brokerIdList.isLoading}
            category={selectedValue?.value}
            setselectedValue={setselectedValue}
            getBrokerList={brokerIdList}
            emailVerfied={User?.emailVerified}
          />
        


        </div>




      </div>
    </div>

  )
}

export default BrokerlistContainer