import React from 'react'
import SubmitButton from '../Common/buttons/SubmitButton'
import Select from "react-select";
import { useState } from 'react';
import { Form } from 'react-bootstrap'
function BrokerIdDetail({ t, selectedValue, setselectedValue, selectData, setsearchParams }) {


  const handleSelectChange = (values) => {
    setselectedValue(values);
    setsearchParams('')
  };
  return (
    <div className="filter_Section">
      <div className="row">
        <div key={"2"} className="col-xl-3 col-md-3">
          <div className="right_search_div">
            <label className="la-control">{t("category")}</label>
            <Select
              options={selectData}
              value={selectedValue}
              onChange={handleSelectChange}

            />

          </div>


        </div>
        {
          selectedValue.value === "request" &&
          <div className='col-md-9'>

            <div className="right_search_div">
              <label className="la-control">{t("search")}</label>
              <Form.Control type="text" placeholder="broker name"   className='responsive-search'  onChange={(e) => setsearchParams(e.target.value)} />
            </div>



          </div>
        }


      </div>
    </div>
  )
}

export default BrokerIdDetail