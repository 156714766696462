import { Brokerservice } from "../../services/broker/broker";
export const getBrokerIdList = async (queries) => {
    try {
        const response = await Brokerservice.getBrokerIdList(queries);
        return response
    } catch (error) {
        console.log(error);
    }
}

export const requestBrokerId=async(data)=>{
    try {
        const response = await Brokerservice.requestBrokerId(data);
        return response
    } catch (error) {
        console.log(error);
    }
}
