import API from "../../api/api";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data;
        } else {
            return response?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}


const postApi = async (endpoint, body) => {
    try {
      const response = await API.post(endpoint, body);
      if (response.status === 200) {
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  
export const Brokerservice = {

    getBrokerIdList: (queries) => {
  
        return callApi(`/get-brokerid-list?category=${queries.category??'request'}&serach=${queries.searchParams}`)
    },
    requestBrokerId:(data)=>{
        return postApi('request-brokerId',data)
    }
}