import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TableSkeleton } from '../Common/table/TableSkeleton'
import { useState } from 'react'
import { ApiHook } from '../../hooks/apiHook'
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { OverlayTrigger, Tooltip, Form, Modal } from 'react-bootstrap'
import SubmitButton from '../Common/buttons/SubmitButton'

function RequestTable({ brokerIdList, loading, category, getBrokerList, emailVerfied }) {

  const queryClient = useQueryClient();
  const { t } = useTranslation()
  const requestBrokerid = ApiHook.CallRequestBrokerid()
  const [selectedBrokerIds, setselectedBrokerIds] = useState([])
  const [accountId, setaccountId] = useState('')
  const [hasClicked, setHasClicked] = useState(false);
  const [showModal, setshowMOdal] = useState(false)
  const [brokerId, setbrokerId] = useState('')
  const [regLink, setregLink] = useState('')
  const headers = {
    request: [
      {
        id: 7,
        name: "logo",

      },
      {
        id: 1,
        name: "Name"
      },
      // {
      //     id: 2,
      //     name: "CompanyIncome"
      // },
      // {
      //     id: 3,
      //     name: "levelCommissionPercentage"
      // },
      // {
      //     id: 4,
      //     name: "personalCashback"
      // },
      // {
      //     id: 5,
      //     name: "rankPoolPercentage"
      // },
      {
        id: 6,
        name: "registration_link"
      },

    ],
    pending: [
      {
        id: 1,
        name: "Name"
      },
      // {
      //     id: 2,
      //     name: "CompanyIncome"
      // },
      // {
      //     id: 3,
      //     name: "levelCommissionPercentage"
      // },
      // {
      //     id: 4,
      //     name: "personalCashback"
      // },
      // {
      //     id: 5,
      //     name: "rankPoolPercentage"
      // },
      {
        id: 6,
        name: "accountId"
      },

    ],
    approved: [
      {
        id: 1,
        name: "Name"
      },
      // {
      //     id: 2,
      //     name: "CompanyIncome"
      // },
      // {
      //     id: 3,
      //     name: "levelCommissionPercentage"
      // },
      // {
      //     id: 4,
      //     name: "personalCashback"
      // },
      // {
      //     id: 5,
      //     name: "rankPoolPercentage"
      // },
      {
        id: 6,
        name: 'accountId'
      }

    ],
    rejected:
      [
        {
          id: 1,
          name: "Name"
        },
        // {
        //     id: 2,
        //     name: "CompanyIncome"
        // },
        // {
        //     id: 3,
        //     name: "levelCommissionPercentage"
        // },
        // {
        //     id: 4,
        //     name: "personalCashback"
        // },
        // {
        //     id: 5,
        //     name: "rankPoolPercentage"
        // },
        {
          id: 6,
          name: 'accountId'
        }

      ],




  }

  const openNewAccount = (url) => {
    window.open(url, "_blank");
  }


  const handleCheckbox = (e, brokerId) => {
    const { checked } = e.target
    if (checked) {
      setselectedBrokerIds([...selectedBrokerIds, brokerId])
    } else {
      setselectedBrokerIds((prevSelected) =>
        prevSelected.filter((id) => id !== brokerId)
      );
    }

  }

  const headerCheckboxHandler = (e) => {
    const { checked } = e.target
    if (checked) {
      const allBrokerList = brokerIdList?.data?.map((items) => {
        return items.id
      })
      setselectedBrokerIds(allBrokerList)
      console.log("====allBrokerList====", allBrokerList)
    } else {
      setselectedBrokerIds([])
    }
  }

  const sendRequestHadler = async () => {
    try {
      if (!accountId) return toast.error(t('accountIdCannotbeEmpty'))
      if (!/^\d+$/.test(accountId)) {
        return toast.error(t('accountIdMustBeInteger'))
      }
      const response = await requestBrokerid.mutateAsync({ brokerId: Number(brokerId), accountId })
      if (response.status) {
        toast.success(t('requestSendsuccessfull'))
        setselectedBrokerIds([])
        queryClient.refetchQueries({ queryKey: ["get-broker-list"] });
        setaccountId('')
        setshowMOdal(false)

      } else {
        if (response.code === 1135) {
          toast.error(t('already_requested'))
          setselectedBrokerIds([])
          setaccountId('')
          setHasClicked(false)
        }else if(response.code===1136){
          toast.error(t('account_id_exist'))
        } else {
          toast.error(t("requestFailed"))
          setselectedBrokerIds([])
          setaccountId('')
        }

      }

    } catch (error) {
      console.log("====request errror")
    }
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Email not verified
    </Tooltip>
  );
  const checkRequestBtnstatus = () => {
    if (emailVerfied) {
      return (
        <button variant="danger" className="CancelButton"
          onClick={sendRequestHadler}
          disabled={requestBrokerid.isLoading}
        >
          {
            requestBrokerid.isLoading ?
              <i class="fa-solid fa-circle-notch fa-spin"></i> :
              <span>
                {t('sendRequest')}
              </span>
          }

        </button>

      )
    } else {
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <button variant="danger" className="CancelButton"
            onClick={sendRequestHadler}
            disabled={true}
            style={{ cursor: 'not-allowed' }}
          >
            {
              requestBrokerid.isLoading ?
                <i class="fa-solid fa-circle-notch fa-spin"></i> :
                <span>
                  {t('sendRequest')}
                </span>
            }

          </button>
        </OverlayTrigger>
      )


    }
  }

  const accountIdhandler = (e) => {
    const { name, value } = e.target
    setaccountId(value)
  }

  const onHide = () => {
    setshowMOdal(false)
  }

  const openAccountId = (brokerId, regLink) => {
    // if (!hasClicked) {
    //   setselectedBrokerIds([...selectedBrokerIds, brokerId]);
    //   setHasClicked(true);
    // }
    setbrokerId(brokerId)
    setregLink(regLink)
    setshowMOdal(true)

  };
  console.log("=====brokerid=====", brokerId)
  return (
    <>
      <div className="table-container" >
        <table className="striped" style={{ width: "100%" }}>
          <thead>

            {

              headers[category].map((items, index) => {
                return (
                  <th>
                    {t(items.name)}
                  </th>

                )
              })

            }

          </thead>
          {
            loading ?
              <tbody>
                <TableSkeleton rowCount={3} cellCount={headers[category].length} />
              </tbody>
              :
              <>
                {
                  brokerIdList?.data.length === 0 &&
                  <tbody>
                    <tr>
                      <td colSpan={headers[category].length}>
                        <div className="nodata-table-view">
                          <div className="nodata-table-view-box">
                            <div className="nodata-table-view-box-img">
                              <img src="/images/no-data-image1.jpg" alt="" />
                            </div>
                            <div className="nodata-table-view-box-txt">
                              {t("sorry_no_data_found")}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
                <tbody>


                  {
                    brokerIdList?.data?.map((items, index) => {
                      const isDisabled = selectedBrokerIds.length > 0 && !selectedBrokerIds.includes(items.id);
                      return (
                        <tr key={index}>

                          {category === "request" && <td>
                            <div style={{ borderRadius: '1em', overflow: 'hidden', width: '50px', height: '50px' }}>
                              <img
                                src={items.brokerLogo}
                                alt="no image"
                                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                              />
                            </div>
                          </td>
                          }
                          <td>{items.name}</td>
                          {category === "request" && <td>
                            <button
                              className="btn text-white mb-2 next rounded-5  open-new-accountbtn"
                              // style={{ background: '#767579' }}
                              onClick={() => openNewAccount(items?.registrationLink)}
                            >
                              {t('open_new_accnt')}
                            </button>
                            <br />
                            <button
                              className="btn text-white next rounded-5  alreadyHaveaccountbtn"
                              // style={{


                              //   cursor: isDisabled ? 'not-allowed' : 'pointer',
                              //   opacity: isDisabled ? 0.5 : 1
                              // }}
                              onClick={() => {
                                // if (!isDisabled) {
                                openAccountId(items.id, items.registrationLink);
                                // }
                              }}
                            >
                              {t('already_have_account')}
                            </button>




                          </td>
                          }

                          {
                            items.accountId && <td>{items.accountId}</td>
                          }

                        </tr>
                      )
                    })
                  }
                </tbody>
              </>

          }

        </table>



      </div>

      {/* modal */}

      <Modal show={showModal} onHide={onHide} dialogClassName="custom-modal-dialog" centered style={{ borderRadius: '1em' }} backdrop="static">
        <Modal.Body style={{ padding: "0px" }}>
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="modal_change_password_right_cnt">
                <div className='accont-id-modal'>
                  <button className='exness_accnt_btn' onClick={() => openNewAccount(regLink)}>{t('opening_a_new_exness_account')}</button>
                  <Form.Label>{t('pls_nter_accnt_number')}</Form.Label>
                  <Form.Control className='accntid-input' type="text" placeholder="MT4/MT5 Number" onChange={accountIdhandler} value={accountId} />
                  <center>
                    {
                      !emailVerfied ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <button className='acntId-submitbtn' style={{ opacity: 0.5, cursor: 'not-allowed' }} disabled={true}>
                            submit
                          </button>
                        </OverlayTrigger>
                        :
                        <button className='acntId-submitbtn' onClick={sendRequestHadler} disabled={requestBrokerid.isLoading}>
                          {
                            requestBrokerid.isLoading ?
                              <i class="fa-solid fa-circle-notch fa-spin"></i> :
                              <span>
                                {t('submit')}
                              </span>
                          }
                        </button>
                    }

                  </center>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={onHide}>
                    {t('close')}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default RequestTable