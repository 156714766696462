import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import SubmitButton from '../../components/Common/buttons/SubmitButton';


export default function CoinPayment({ handleSubmit, coins }) {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedCoin, setSelectedCoin] = useState("");

    const handleSelectChange = (values) => {
        setSelectedValue(values);
        setSelectedCoin(values.value)
    };

    return (
        <>
            <div className="right_search_div">
                <label className="la-control">{t("select-coin")}</label>
                <Select
                    options={coins}
                    value={selectedValue}
                    onChange={handleSelectChange}
                />
            </div>
            <div className="checkout_continuew_btn">
                {
                    <button
                        className="btn btn-primary checkout_cnt_btn"
                        disabled={!selectedValue}
                        onClick={() => handleSubmit(8, selectedCoin)}
                    >
                        {t("pay")}
                    </button>
                }
            </div>
        </>
    )
}
