import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';

const MailCompose = ({ showCompose, setShowCompose, setPage, setApiTab, activeTab }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { register, trigger, watch, setValue, setError, reset, clearErrors, formState: { errors } } = useForm({
    defaultValues: {
      type: "individual",
      username: "",
      subject: "",
      message: "",
    }
  });
  const formValues = watch();
  const [composeMailPayload, setComposeMailPayload] = useState({
    message: "",
  });

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const composeInternalMailMutation = ApiHook.CallSendInternalMail(composeMailPayload);  // API

  const handleComposeMail = async (e) => {
    e.preventDefault();
    let isValid;
    isValid = await trigger();
    if (isValid) {
      composeInternalMailMutation.mutateAsync(formValues, {
        onSuccess: (res) => {
          if (res?.status) {
            toast.success(t(res?.data));
            setShowCompose(false);
            setApiTab(activeTab);
            setPage(1);
            queryClient.invalidateQueries({ queryKey: [activeTab] });
            setComposeMailPayload({
              message: "",
            });
            reset();
          } else {
            var error = ""
            if (res?.data?.description) {
              if (res.data.description === "invalidUsername") {
                setError("username", { message: ("invalidUsername") })
              }
              error = res?.data?.description
            } else {
              error = "An error occurred, please try again later"
            }
            toast.error(t(error));
          }
        },
      });
    }
  };

  const handleMailContentChange = (value) => {
    setValue("message", value);
    setComposeMailPayload((prevData) => ({
      ...prevData,
      message: value,
    }));
    clearErrors("message");
  };


  const handleTypeChange = (value) => {
    console.log(value, "value");
    setComposeMailPayload((prevData) => ({
      ...prevData,
      type: value
    }))
  }
  return (
    <>
      <Offcanvas
        id="composemail"
        show={showCompose}
        onHide={() => setShowCompose(false)}
        placement="end"
        style={{ backgroundColor: "white" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("new_mail")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <main>
            <Form>
              {/* <Form.Group style={{ display: "inline-flex" ,gap : '10px' }}> 
                <Form.Label>{t("to")}  </Form.Label>

                <Form.Check
                  type="radio"
                  label={t("individual")}
                  name="option"
                  id="individual"
                  value="individual"
                  checked={composeMailPayload.type === "individual"}
                  onChange={(e) => handleTypeChange(e.target.value)}
                />

                <Form.Check
                  type="radio"
                  label={t("team")}
                  name="option"
                  id="team"
                  value="team"
                  checked={composeMailPayload.type === "team"}
                  onChange={(e) => handleTypeChange(e.target.value)}
                />

              </Form.Group>
              <p>{t("selected_option")}: {t(composeMailPayload.type)}</p> */}

              {formValues.type === "individual" &&
                <Form.Group className="mb-3" controlId="to">
                  <Form.Label>{t('to')}</Form.Label>
                  <Form.Control
                    {...register("username", { required: { value: 1, message: ("this_field_is_required") } })}
                    onBlur={async () => await trigger("username")}
                    type="text"
                    placeholder={t("username")}
                  />
                  {errors["username"] && (
                    <span className="error-message-validator">
                      {t(errors["username"].message)}
                    </span>
                  )}
                </Form.Group>
              }
              <Form.Group className="mb-3" controlId="to">
                <Form.Label>{t('subject')}</Form.Label>
                <Form.Control
                  {...register("subject", {
                    required: { value: true, message: ("this_field_is_required") }
                  })}
                  onBlur={async () => await trigger("subject")}
                  type="text"
                  placeholder={t('subject')}
                  maxLength={67} // Set the maximum length to 100 characters
                />
                {errors["subject"] && (
                  <span className="error-message-validator">
                    {t(errors["subject"].message)}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mt-4" style={{ height: "230px" }}>
                <ReactQuill
                  value={composeMailPayload.message}
                  onChange={handleMailContentChange}
                  modules={modules}
                  style={{ height: "200px" }}
                  maxLength={3000} // Set the maximum length to 3000 characters
                />
              </Form.Group>
              {errors["message"] && (
                <span style={{ paddingTop: "15px" }} className="error-message-validator">
                  {t(errors["message"].message)}
                </span>
              )}
              <Form.Group className="mt-4">
                <Button
                  variant="success"
                  type="submit"
                  onClick={handleComposeMail}
                  disabled={composeInternalMailMutation.status === "loading"}
                >
                  {t("send")}
                </Button>
              </Form.Group>
            </Form>
          </main>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MailCompose;