import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    walletCriterias: {}
};

const userSlice = createSlice({
    name: 'payout',
    initialState,
    reducers: {
        setWalletCriteria: (state, action) => {
            state.walletCriterias = action.payload;
        },

    },
});

export const { setWalletCriteria } = userSlice.actions;

export default userSlice.reducer;
